import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Card({
  title,
  children,
  className,
  inner,
  innerInner,
}: {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  inner?: boolean;
  innerInner?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className={`${inner ? 'card-inner' : innerInner ? 'card-inner-inner' : 'card'}${' '}${className}`}>
      {title && <h3 className="mb20">{t(title)}</h3>}
      {children}
    </div>
  );
}
