import React from 'react';

const ActiveCampaignsStatus = ({ text, color, size }: { text: string; color?: string; size?: string }) => {
  return (
    <div className={`percentage-difference-container ${color || 'blue'}`}>
      <p className={`status-text ${color || 'blue'} ${size}`}>{text}</p>
    </div>
  );
};

export default ActiveCampaignsStatus;
