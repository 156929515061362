import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Avatar, Box, Button, Chip, Icon, Modal, Tab, Tabs } from '@mui/material';

import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import { InsightsTabs } from '@/models/Enums';

import ButtonComponent from '../microcomponents/Button';
import MenuModal from './MenuModal';

const UserHeader = ({
  title,
  isProfile,
  isHome,
  isInsights,
  isSubscribe,
}: {
  title: string;
  isProfile?: boolean;
  isHome?: boolean;
  isInsights?: boolean;
  isSubscribe?: boolean;
}) => {
  const navigate = useNavigate();
  const { account, refetchAccount } = useAccountContext();
  const { artist } = useArtist();
  const [params, setSearchParams] = useSearchParams();
  const tab = params.get('tab');

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>();

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);
  const [socials, setSocials] = useState<
    {
      name: string;
      title: string;
      logo: string;
    }[]
  >([]);

  const tikTokRedirectUri = `${window.location.origin}/tiktok-callback`;
  const tikTokAppID = import.meta.env.VITE_TIKTOK_APP_ID;

  const facebookAppId: string = import.meta.env.VITE_FACEBOOK_APP_ID || '';
  const facebookRedirectUri = `${window.location.origin}/meta-callback`;

  const state = '';

  const tikTokRoute = `https://business-api.tiktok.com/portal/auth?app_id=${tikTokAppID}&state=${state}&redirect_uri=${tikTokRedirectUri}`;
  const facebookRoute = `https://m.facebook.com/v18.0/dialog/oauth?app_id=${facebookAppId}&redirect_uri=${facebookRedirectUri}&state=${state}&response_type=token`;

  const loginToSocial = (route: string) => {
    window.open(`${route}`, '', 'popup=true,width=500px,height=800px');
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'Meta logged in' || event.data === 'TikTok logged in') {
          refetchAccount();
        }
      },
      true
    );
  };

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  const handleClick = (value: { name: string; title: string; logo: string }) => {
    if (value.name === 'tiktok') {
      loginToSocial(tikTokRoute);
    } else if (value.name === 'facebook') {
      loginToSocial(facebookRoute);
    }
  };

  const isTikTok = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'tiktok')[0]?.accessToken,
    [account?.accessTokens]
  );
  const isMeta = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'meta')[0]?.accessToken,
    [account?.accessTokens]
  );

  const setSocialChips = useCallback(() => {
    if (!account) return;

    const socialChips = [];
    if (!isTikTok) {
      socialChips.push({
        name: 'tiktok',
        title: 'BRAND.TIKTOK',
        logo: '/images/logos/tiktok-logo-white.svg',
      });
    }
    if (!isMeta) {
      socialChips.push({
        name: 'facebook',
        title: 'BRAND.META',
        logo: '/images/logos/meta-logo-white.svg',
      });
    }
    setSocials(socialChips);
  }, [account, isMeta, isTikTok]);

  const updateParams = useCallback(() => {
    if (!value) return;
    if (tab && tab !== value) {
      setSearchParams({ tab: value, platform: 'overview' }, { replace: false });
      setValue(tab);
    }
  }, [setSearchParams, tab, value]);

  useEffect(() => {
    if (!isInsights) return;
    updateParams();
  }, [isInsights, updateParams]);

  useEffect(() => {
    setSocialChips();
  }, [setSocialChips]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <>
          <MenuModal closeModalOutput={() => setOpen(false)} />
        </>
      </Modal>
      <div className="card">
        <div className="d-flex jc-space-between">
          <h3 className="title pt4" data-testid="main-card-title">
            {isHome && account?.contact?.firstName ? `Hi, ${account?.contact.firstName}` : t(title)}
          </h3>
          <div className="d-flex">
            {isSubscribe && (
              <Button className="btn-white" data-testid="upgrade-subscription-button">
                Upgrade Subscription
              </Button>
            )}
            <ButtonComponent isCustom onClick={() => navigate('/profile')} ariaLabel="go-to-profile-page">
              <img
                data-testid="profile-pic"
                className={isProfile ? 'profile-pic on-profile mt0 mb0' : 'profile-pic mt0 mb0'}
                src={
                  artist?.details?.profileImageUrl ? artist.details.profileImageUrl : '/images/profile-placeholder.svg'
                }
                alt=""
              />
            </ButtonComponent>
            {breakPointHit && (
              <Button className="icon-btn mt0 mb0 mr0" data-testid="menu-button" onClick={() => setOpen(true)}>
                <Icon className="material-symbols-outlined">menu</Icon>
              </Button>
            )}
          </div>
        </div>

        {isHome && socials.length > 0 && (
          <div className="d-flex jc-start gap16 overflow-scroll pb10 mt8">
            {socials.map((item, index: number) => (
              <Chip
                key={index}
                avatar={<Avatar alt={item.name} src={item.logo} />}
                label={t('COMMON.CONNECT') + ' ' + t(item.title)}
                data-testid={`social-chip-${item.name}`}
                onClick={() => handleClick(item)}
              />
            ))}
          </div>
        )}
        {isInsights && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <Tabs
              className="title-tabs"
              value={value || InsightsTabs.SOCIALS}
              onChange={(_, newValue) => setValue(newValue)}
            >
              <Tab value={InsightsTabs.SOCIALS} label={t('COMMON.SOCIALS')} data-testid="insights-socials" />
              <Tab value={InsightsTabs.MUSIC} label={t('COMMON.MUSIC')} data-testid="insights-music" />
            </Tabs>
          </Box>
        )}
      </div>
    </>
  );
};

export default UserHeader;
