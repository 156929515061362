import React from 'react';

import { Icon } from '@mui/material';

import { Images } from '@/constants/Images';

import ButtonComponent from './Button';

export default function ListItem({
  icon,
  title,
  subtitle,
  button,
  arrow,
  onClick,
  className,
}: {
  icon?: string;
  title?: string;
  subtitle?: string | React.ReactNode;
  button?: React.ReactNode;
  arrow?: boolean;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <>
      <ButtonComponent isCustom={true} className={`card-inner w100p ${className}`} onClick={onClick}>
        <div className="d-flex list-item pos-rel">
          <object className="playlist-image" data={icon ? icon : '/images/logos/unhurd-logo.png'} type="image/png">
            <img className="playlist-image" src={Images.unhurdFallback} alt="Fallback playlist img" />
          </object>
          <div className={`flex-grow text-white text-left ${button ? 'mb48' : ''}`}>
            <div className="fs-16 fw-bold">{title}</div>
            <div className="text-faded small d-flex">{subtitle}</div>
          </div>
          {button && <div className="pos-abs r0 b0">{button}</div>}
          {arrow && <Icon className="text-faded">chevron_right</Icon>}
        </div>
      </ButtonComponent>
    </>
  );
}
