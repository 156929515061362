const useScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToTopInPromoteFlows = (behavior?: ScrollBehavior) => {
    document
      .getElementById('number-stepper')
      ?.scrollIntoView({ behavior: behavior, block: 'start', inline: 'nearest' });
  };

  return {
    scrollToTop: scrollToTop,
    scrollToTopInPromoteFlows: scrollToTopInPromoteFlows,
  };
};

export default useScrollToTop;
