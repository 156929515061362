import React, { ReactNode } from 'react';

import { Button as MuiButton } from '@mui/material';

const ButtonComponent = ({
  children,
  role,
  isCustom,
  className,
  style,
  onClick,
  ariaLabel,
  tabIndex,
}: {
  children: ReactNode;
  role?: string;
  isCustom?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  ariaLabel?: string;
  tabIndex?: number;
}) => {
  if (isCustom) {
    return (
      <button
        onClick={onClick}
        className={`custom-button ${className}`}
        style={style}
        aria-label={ariaLabel}
        role={role}
        tabIndex={tabIndex}
      >
        {children}
      </button>
    );
  }
  return (
    <MuiButton className={className} onClick={onClick}>
      {children}
    </MuiButton>
  );
};

export default ButtonComponent;
