import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@mui/material/Icon';

import useAccountContext from '@/hooks/context/useAccountContext';

import ButtonComponent from '../microcomponents/Button';

const Logout = ({ minimize, inButton }: { minimize?: boolean; inButton?: boolean }) => {
  const { t } = useTranslation();

  const { logoutUser } = useAccountContext();

  return (
    <ButtonComponent
      isCustom
      className={`${minimize ? 'logout-button minimize' : 'logout-button'} text-white w100p`}
      onClick={() => {
        logoutUser();
      }}
      data-testid="sign-out-button"
    >
      <Icon className={`logout-icon text-white" ${inButton ? 'pt0' : ''}`}>logout</Icon>
      {!minimize && <p className={`logout-text text-white ${inButton ? 'small pt4' : ''} `}>{t('COMMON.SIGN-OUT')}</p>}
    </ButtonComponent>
  );
};

export default Logout;
