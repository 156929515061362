import { useEffect, useState } from 'react';

const useBreakpoints = () => {
  const [isSmall, setIsSmall] = useState<boolean>();
  const [isMedium, setIsMedium] = useState<boolean>();
  const handleResize = () => {
    window.innerWidth <= 900 ? setIsMedium(true) : setIsMedium(false);
    window.innerWidth <= 780 ? setIsSmall(true) : setIsSmall(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    breakpointHit: isMedium,
    breakpointHitSmall: isSmall,
  };
};

export default useBreakpoints;
