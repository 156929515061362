import React, { RefObject, useEffect, useState } from 'react';

import { Button, Icon } from '@mui/material';

import useScrollToTop from '@/hooks/utility/useScrollToTop';

const ScrollToTopButton = ({
  inPromoteFlow,
  scrollRef,
}: {
  inPromoteFlow?: boolean;
  scrollRef?: RefObject<HTMLDivElement>;
}) => {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState<boolean>(false);
  const { scrollToTop, scrollToTopInPromoteFlows } = useScrollToTop();

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.onscroll = (e: Event) => {
        if ((e.target as HTMLDivElement)?.scrollTop > 500) {
          setShowScrollToTopButton(true);
        } else {
          setShowScrollToTopButton(false);
        }
      };
    } else {
      window.onscroll = () => {
        if (window.scrollY > 500) {
          setShowScrollToTopButton(true);
        } else {
          setShowScrollToTopButton(false);
        }
      };
    }
  }, [scrollRef]);

  return (
    <>
      {showScrollToTopButton && (
        <Button
          className={`scroll-to-top-button ${inPromoteFlow ? 'in-promote-flow' : ''}`}
          onClick={() => {
            if (inPromoteFlow) {
              return scrollToTopInPromoteFlows('smooth');
            }
            return scrollToTop();
          }}
        >
          <Icon>arrow_upward</Icon>
        </Button>
      )}
    </>
  );
};

export default ScrollToTopButton;
